<template>
  <div class="oneThing">
    <div class="nav">
      <img :src="typeId == 1?personUrl:url" alt="">
    </div>
    <div class="details">
      <van-cell  is-link  v-for="i in dataList" :title="i.columnName" :key="i.id" @click="btnThing(i)">
        <template #icon>
          <!-- <van-icon name="search" class="shop-o" /> -->
          <img class="icon" :src="i.columnIcon" alt="">
        </template>
      </van-cell>
    </div>
  </div>
</template>
<script>
import {getChildSceneColumn ,getSceneContentByColumnId} from '@/api/home.js'
export default {
  name:"oneThing",
  data() {
    return {
      dataList:[],
      url:require('../../../assets/faren.png'),
      personUrl:require('../../../assets/gerenyijian.png'),
      // typeId:this.$route.query.id
    }
  },
  created() {
    this.init()
  },
  computed:{
    topTittle(){
      return this.$store.state.topTittle
    },
    typeId(){
      return this.$route.query.id
    }
  },
  methods: {
    async init(){
      if(this.$store.state.jxbApp){
        window.location.href=`ssptitle://${this.topTittle}`
      }else{
        document.title = this.topTittle
      }
      const data = await getChildSceneColumn({
        "pageNumber": 1,
        "pageSize": 10,
        "parentId": this.$route.query.columnId
      })
      this.dataList = data.data.data.rows
    },
    async btnThing(res){
      // 
      
      if(res.columnType == 1){
        localStorage.setItem('tittle',res.columnName)
        let data = await getSceneContentByColumnId({
          "columnId": res.id,
          "pageNumber": 1,
          "pageSize": 10,
          "title": ""
        })
        if(res.columnAttr == 1){
          this.$router.push({path:"/scContent",query:{typeId:5,id:res.id}})
        }else{
          if(data.data.data.rows[0].detailType == 1){
            if(data.data.data.rows[0].type == 1){
              this.$router.push({path:"/scContent",query:{typeId:1,id:data.data.data.rows[0].id}})
            }else if(data.data.data.rows[0].type == 2){
              this.$router.push({path:"/scContent",query:{typeId:2,id:data.data.data.rows[0].id}})
            }else{
              this.$router.push({path:"/scContent",query:{typeId:3,id:data.data.data.rows[0].id}})
            }
          }else{
            this.$router.push({path:"/scContent",query:{typeId:4,id:data.data.data.rows[0].id}})
          }
        }
        
      }else if(res.columnType == 2){
        this.$store.commit('setTopTittle',res.columnName)
        this.$store.commit('setListId',res.listType)
        this.$router.push({path:'/oneThing',query:{columnId:res.id}})
      }else{
        location.href = res.columnLink
      }
    }
  },
}
</script>
<style lang="less" scoped>
.oneThing{
  .nav{
    img{
      width: 100%;
      height: 4.267rem;
    }
  }
  .details{
    .icon{
      width: .596rem;
      height: .596rem;
      margin-right: .204rem;
      }
  }
  /deep/.van-cell::after{
    border-bottom:none
  }
  /deep/.van-cell{
    border-bottom: .009rem solid #ebedf0;
    align-items: center;
    .van-cell__title, .van-cell__value{
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>